@import './colors.scss';
.dashboard {
  .react-page-controls-mode-toggle-control-group {
    position: fixed !important;
    right: 50px !important;
    top: 50% !important;
    transform: translateY(-25%);
    height: 100%;
  }

  .react-page-cell-inner {
    padding: 8px;
  }

  width: 100%;

  .dashboard-container {
    //display: grid;
    //padding-top: 8px;
    //grid-template-columns: repeat(12, 1fr);
    //grid-auto-rows: 70px;
    //grid-template-columns: repeat(12, 1fr);
    //grid-gap: 8px;
    //> div {
    //  background: var(--dashboard-cards-bg);
    //}
    .card {
      border: none !important;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
      color: var(--dashboard-cards-text);
    }
  }

  .btn-block.dropdown .btn {
    background: transparent !important;
    border: none !important;
    color: var(--text-color);
  }

  .dark-widget *,
  .dark-widget .widget {
    background-color: var(--dashboard-cards-bg);
    color: var(--dashboard-cards-text);
  }

  .widget {
    background-color: var(--dashboard-cards-bg);
    color: var(--dashboard-cards-text);

    font-size: 12px;

    &.low-pad {
      padding: 8px;
    }

    .widget-container {
      overflow: auto;
      .table {
        display: table;
        table-layout: fixed;
        padding-right: '0px';
        overflow: auto;
        .table-row:not(.dark-bg) {
          .table-cell {
            background: var(--table-body);
          }
        }
        .table-row {
          display: table-row;
          .table-cell {
            display: table-cell;
            width: fit-content;
            max-width: unset;
          }
        }
      }
    }

    .table-cell {
      padding: 6px 4px;
      margin: 1px;
    }

    .dark-bg {
      background-color: var(--table-header);
      color: white;
      margin-bottom: 0;

      p {
        margin-bottom: 0;
        color: white;
      }
    }

    &.no-pad {
      padding: 0;
    }

    &.right-border:after {
      content: '';
      position: absolute;
      height: 50%;
      width: 2px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.right-border.up:after {
      background: #00a3a5;
    }

    &.right-border.down:after {
      background: #ff0201;
    }

    &.left-border:before {
      content: '';
      position: absolute;
      height: 50%;
      width: 2px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.left-border.up:before {
      background: #00a3a5;
    }

    &.left-border.down:before {
      background: #ff0201;
    }
  }

  .down .right-container h5 {
    color: #ff0201 !important;
  }

  .single-value {
    padding-left: 16px;
    padding-right: 16px;

    h6 {
      font-size: 14px;
      color: #506574;
      line-height: 19px;
      font-weight: 400;
    }

    h5 {
      //padding-top: 12px;
      height: 44px;
      line-height: 44px;
    }

    .left-container {
      h5 {
        font-size: 12px;
        color: var(--dashboard-cards-text);
        font-weight: 600;
      }

      svg {
        margin-right: 8px;
        transform: translateY(-2px);

        &.rotate {
          transform: translateY(-4px) rotateZ(135deg);
        }
      }
    }

    .right-container {
      h5 {
        font-size: 18px;
        color: #00a3a5;
        font-weight: 700;
      }
    }
  }
  .rss-reader-title {
    p {
      text-transform: capitalize;
    }
  }
  .rss-reader-entry {
    .image {
      height: 85px;
      width: 135px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 0;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      opacity: 1;
      overflow: hidden;
    }
    .content {
      margin-top: -4px;
      max-height: 80px;
      line-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: wrap;

      .title {
        line-height: 25px;
        color: var(--dashboard-cards-text);
        font-weight: bold;
      }
      .body {
        line-height: 10px;
      }
    }
    .date {
      margin-top: -10px;
      margin-bottom: 15px;
    }
  }
  .enquiries-body {
    overflow-y: auto;
    .enquiries-container {
      overflow-y: auto;
      .enquiry {
        cursor: pointer;
        &:hover,
        &:focus {
          background: var(--table-row-selected-background);
          color: var(--table-row-selected-text-color);
        }
        .enquiry-key {
          float: right;
          padding-right: 10px;
          .display-key {
            display: block;
            float: right;
            visibility: hidden;
            &:active {
              visibility: visible;
            }
          }
        }
      }
    }
  }
  #select-template {
    float: right;
  }
  #enquiry-template-dropdown {
    padding: 0;
    font-weight: bold;
    color: var(--dashboard-cards-text);
  }
  .links-container {
    overflow-y: scroll;

    .link {
      cursor: pointer;
      overflow-y: auto;

      .icon-list {
        float: right;

        .autoplay,
        .remove-link {
          display: block;
          float: right;
          visibility: hidden;

          svg {
            margin: 0 4px 0 4px;
          }

          &.active {
            visibility: visible;
          }
        }

        .remove-link {
          svg path {
            stroke: var(--primary-dark-svg);
          }
        }
      }
    }

    &:not(.dragging) {
      .link {
        &:hover,
        &:focus {
          background: var(--table-row-selected-background);
          color: var(--table-row-selected-text-color);

          .autoplay,
          .remove-link {
            visibility: visible;
          }
        }
      }
    }

    &.dragging {
      .link {
        &.dragging-clone {
          background: var(--table-row-selected-background);
          color: var(--table-row-selected-text-color);

          .autoplay,
          .remove-link {
            visibility: visible;
          }
        }
      }
    }
  }
}

.no-pad {
  padding: 0 !important;
}

.no-mar {
  margin: 0 !important;
}

.aperio-dashboard-container {
  height: calc(100% - 8px);
}
