@import 'colors';

.small {
  left: 50%;
  transform: translateX(-50%);
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.large {
  width: 100%;
}
.error {
  background: #{nth($pink, 4)};
}
.warning {
  background: $warning;
}
.error-main {
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  position: absolute;
  height: 64px;
  box-shadow: 0 0 16px rgba(80, 101, 116, 0.4);
  border-radius: 4px;
  color: #{nth($grey, 7)};
  z-index: 98;
  span {
    flex: 1 1 auto;
    position: relative;
    //height: 20px;
    text-wrap: none;
    margin: 20px 16px 22px 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    padding: -1px;
    align-items: center;
  }
  button {
    flex: 0 0 auto;
    margin: 12px;
    padding: 0 24px 0 24px;
    height: 40px;
    color: inherit;
    background: transparent;
    border: 1px solid #{nth($grey, 7)};
    box-sizing: border-box;
    border-radius: 4px;
  }
}
.alert-symbol {
  flex: 0 0 auto;
  margin: 20px 0 20px 32px;
  path {
    stroke: #{nth($grey, 7)};
  }
}
.alert-symbol-message {
  //position: absolute;
  left: 25px;
  top: 25px;
  path {
    stroke: #{nth($blue-grey, 1)};
  }
}
.close-cross {
  position: absolute;
  right: 25px;
  top: 25px;
  path {
    stroke: #{nth($blue-grey, 1)};
  }
}
.more-errors {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background: rgba(80, 101, 116, 0.24);
  //backdrop-filter: blur(24px);
  z-index: 111;
  .error-list-panel {
    min-width: 50%;
    height: inherit;
    position: absolute;
    right: 0;
    background-color: #{nth($grey, 7)};
    .details-heading {
      width: 100%;
      height: 75px;
      position: absolute;
      .message-heading {
        position: absolute;
        left: 61px;
        top: 25px;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #{nth($blue-grey, 1)};
      }
    }
  }
}
.details-list {
  width: 100%;
  position: absolute;
  top: 75px;
  .message-details {
    padding: 24px 30px 24px 25px;
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: var(--error-messages-subheading);
    }
    .body {
      margin-top: 16px;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: var(--error-messages-secondary-text);
    }
  }
}
.bottom-border {
  border-bottom: #{nth($blue-grey, 5)} solid 1px;
}
