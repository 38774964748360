@import 'colors';

td .img-clock {
  top: 5px !important;
}

.input-wrapper {
  position: relative;
  .img-clock {
    height: 14px;
    width: 14px;
    position: absolute;
    right: 2px;
    align-self: center;
  }
  .text-style {
    width: 100%;
  }
}
.time-input-container {
  position: relative;
  height: 100%;
}

.time-popup-wrapper {
  position: fixed;
  z-index: 999;
  background: var(--timepicker-background);
  box-shadow: 0px 0px 16px rgba(65, 81, 94, 0.24);
  border-radius: 2px;
  padding: 24px;
  display: flex;
  justify-content: center;
  .hours-wrapper {
    display: flex;
    flex-direction: column;
    label {
      font-size: 10px;
      line-height: 14px;
      color: var(--timepicker-label-text);
      text-align: left;
      margin-bottom: 8px;
    }
    input {
      width: 40px;
      text-align: center;
      &:focus {
        background: var(--edit-input-field);
        border: 2px solid #{nth($primary, 3)};
        outline: none !important;
        color: #{nth($blue-grey, 1)};
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
  .colon-wrapper {
    margin: 0 8px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: unset;
    color: var(--text-color);
  }
  .ampm-wrapper {
    border-radius: 4px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: 24px;
    .am-block,
    .pm-block {
      padding: 10px 8px;
      font-size: 14px;
      line-height: 19px;
      color: #{nth($blue-grey, 5)};
      background: #eef0f2;
      cursor: pointer;
      &.selected {
        border: 1px solid #{nth($blue-grey, 3)};
        background: #{nth($grey, 7)};
      }
    }
    .am-block {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .pm-block {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.colon-wrapper label {
  margin-bottom: unset;
}

.time-picker-open {
  background: var(--edit-input-field);
  border: 2px solid #{nth($primary, 3)};
  color: #{nth($blue-grey, 1)};
}

.time-picker:focus {
  background: var(--edit-input-field);
  border: 2px solid #{nth($primary, 3)};
  outline: none !important;
}
