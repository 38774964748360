@import './colors.scss';

.request-error-modal {
  background: transparent;
  z-index: 2001;

  .row {
    align-items: center;
  }
  .column-icon {
    padding: 0px;
    margin-bottom: 6px;
  }
  h5 {
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 20px;
  }
  h6 {
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
  }
}
