@import '../styles/colors';

@mixin background {
  background-image: var(--checkbox-checkmark);
  background-position-y: 2px;
  background-size: 12px;
  //background-position-y: 2px;
}
.custom-checkbox-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.custom-checkbox {
  min-height: 24px;
  .custom-control-label::before {
    border: 1px solid var(--input-form-control-border);
    background-color: var(--checkbox-background-color);
    border-radius: 2px;
  }
  .custom-control-label {
    color: #{nth($blue-grey, 1)};
    &::after,
    &::before {
      top: 0px;
      position: absolute;
      left: -24px;
      display: block;
      width: 20px;
      height: 20px;
      margin-top: 2px;
      content: '';
      background: 50%/50% 50% no-repeat;
    }
  }
  .custom-control-input {
    height: 20px;
    width: 20px;
    &.is-invalid {
      ~ .custom-control-label {
        &::before {
          border: 2px solid $error !important;
        }
      }
    }
    &.is-invalid:focus {
      ~ .custom-control-label {
        &::before {
          border: 3px solid $error !important;
        }
      }
    }
    &:focus:not(.is-invalid) {
      ~ .custom-control-label {
        &::before {
          border: 3px solid #{nth($primary, 3)} !important;
        }
      }
    }
    &:checked:not(:indeterminate) {
      ~ .custom-control-label {
        &::before {
          background-color: var(--checkbox-background-checked);
          border: 1px solid var(--input-form-control-border);
        }
        &::after {
          //top: 0.25rem;
          @include background;
        }
        line {
          stroke: #00a3a5;
        }
      }
    }
    &:disabled {
      ~ .custom-control-label::before {
        background-color: var(--disabled-checkbox-bg);
        border: 1px solid var(--disabled-input-border);
      }
      &:indeterminate ~ .custom-control-label::before {
        background-color: transparent;
      }
      ~ .custom-control-label {
        color: #{nth($grey, 3)};
      }
      &:checked {
        ~ .custom-control-label {
          &::before {
            background-color: var(--disabled-checkbox-bg);
            border: 1px solid var(--disabled-input-border);
          }
        }
      }
    }
    &:focus ~ .custom-control-label::before {
      box-shadow: none !important;
    }
    &:indeterminate {
      ~ .custom-control-label::before {
        border: 1px solid #{nth($blue-grey, 1)};
        background-color: #{nth($light-teal, 3)};
      }
      ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%232f3c46' d='M0 2h4'/%3e%3c/svg%3e");
      }
    }
  }
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #{nth($blue-grey, 1)};
}

.data-table-span {
  .custom-checkbox-container:focus-within {
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    background: var(--table-row-selected-background);
    margin: 2px;
  }
  .custom-control-label::before {
    border: 1px solid var(--checkbox-border);
    background-color: var(--checkbox-background-color);
    border-radius: 2px;
  }
}

.checkbox-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  background: transparent;
}
