@import 'colors';

.text-style {
  background-color: var(--input-form-control);
  border: 1px solid var(--input-form-control-border);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;
  color: var(--text-color);
  stroke: #00a3a5;
  &:disabled {
    color: var(--disabled-input-text);
    background-color: var(--disabled-input-background);
    border: 1px solid var(--disabled-input-border);
  }
  &:read-only:not(.time-picker) {
    color: var(--disabled-input-text);
    background-color: var(--disabled-input-background);
    border: 1px solid var(--disabled-input-border);
  }
  &:hover {
    border-color: var(--text-input-hover);
  }
}

.text-style[readonly]:not(.time-picker) {
  &:focus {
    border: 1px solid #{nth($primary, 3)} !important;
    background-color: var(--disabled-input-background) !important;
  }
}

.input-group.multiline {
  height: 100%;
}

:not(.prompt), :not(.combo), :not(.combo-editable) {
  &.input-group > .form-control:focus {
    border: 2px solid #{nth($primary, 3)};
    background: var(--edit-input-field);
    //box-shadow: 0px 0px 2px 0px #{nth($primary, 3)}
  }
}

.prompt, .combo, .combo-editable {
  &.input-group > .form-control:focus {
    border: 2px solid #{nth($primary, 3)};
    background: var(--edit-input-field);
    & ~ .input-group-append {
      background: var(--edit-input-field);
      border-radius: 4px;
    }
    //box-shadow: -1px 0px 2px 0px #{nth($primary, 3)}
  }
}

.prompt, .combo, .combo-editable {
  &.input-group > .form-control:hover {
    border: 2px solid var(--text-input-hover);
    //box-shadow: -1px 0px 2px 0px #{nth($primary, 3)}
  }
}
.input-group > .form-control:hover ~ .input-group-append .text-font.input-group-text {
  border: 2px solid var(--text-input-hover);
  //box-shadow: 1px 0px 2px 0px #{nth($primary, 3)}
}

.input-group > .form-control:focus ~ .input-group-append .text-font.input-group-text {
  border: 2px solid #{nth($primary, 3)};
  //box-shadow: 1px 0px 2px 0px #{nth($primary, 3)}
}

.row-wrapper {
  overflow: visible !important;
  *:not(.autocomplete-dropdown) {
    overflow: visible !important;
  }
}

.autocomplete-dropdown {
  box-shadow: 0px 0px 8px rgba(80, 101, 116, 0.32);
  max-height: 200px;
  height: auto;
  //bottom: 40px;
  position: absolute;
  overflow: auto;
  max-width: 50vw;
  background: white;
  z-index: 200;
  &.open-top {
    bottom: 10px;
  }

  li.active {
    background-color: #{nth($light-teal, 3)};
    font-weight: 600;
  }
}

.is-invalid,
.is-invalid ~ .input-group-append .text-font.input-group-text {
  //color: black !important;
  border: 1px solid $error !important;
  background: #f8bac6 !important;
}

//.text-font {
//  height: 30px;
//}
//.padding {
//  padding: 7px 8px 7px 8px;
//}

[data-hiddendata='true'] {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.text-font {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  height: 24px;
}

.padding {
  padding: 1px 2px;
}

.text-style:placeholder-shown {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
  padding-right: 2px !important;
}

.error-msg-style {
  height: 16px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $error;
  margin-top: 4px;
  width: 100%;
  display: flex;
  font-style: italic;
}

.prompt, .combo, .combo-editable {
  input.form-control {
    border-right-width: 0 !important;
  }
}

.prompt, .combo, .combo-editable {
  .input-group-append {
    background-color: var(--input-group-icon);
    cursor: pointer;
  }
}

.input-group-append .text-font.input-group-text {
  width: 16px;
  padding: 0px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  //background-color: var(--input-group-icon);
  background-color: #00000000;
  border: 1px solid var(--input-form-control-border);
  border-left-width: 0 !important;
  img {
    width: 100%;
  }
}
.auto-capitalise {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}

.auto-complete-item {
  cursor: pointer;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}
