/*
The CSS export should solely be used as a reference.
For a more detailed animation export the JSON file should be used.
*/
#loader-overlay {
  position: fixed;
  z-index: 9998;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: transparent;
  //backdrop-filter: blur(2px);
  cursor: progress;
}

#loader_container {
  z-index: 9999;
  position: absolute;
  top: calc(50%);
  left: calc(50%);
  transform: translate(-50%, -50%);
  text-align: center;

  &.login {
    left: auto;
    right: 16.6666%;
    transform: translate(50%, -50%);
  }
  #loader-text {
    color: var(--global-loader-text);
    font-size: 24px;
    line-height: 32.68px;
    font-weight: 600;
    text-align: center;
    animation: hide_loader_animation 0s 1s forwards;
    visibility: hidden;
  }
  #loader-bg {
    height: 64px;
    width: 64px;
    border-radius: 4px;
    background-color: lightgrey;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    animation: hide_loader_animation 0s 1s forwards;
    visibility: hidden;

    #loader_part_1_cover {
      position: absolute;
      width: 31px;
      height: 7.5px;
      left: 6px;
      top: 18px;
      background: #00a3a5;
      border-radius: 15.5px;
    }

    #loader_part_2_cover {
      position: absolute;
      width: 26px;
      height: 7.5px;
      left: 27px;
      top: 28px;
      background: #00a3a5;
      border-radius: 15.5px;
    }

    #loader_part_3_cover {
      position: absolute;
      width: 20px;
      height: 7.5px;
      left: 22px;
      top: 38px;
      background: #00a3a5;
      border-radius: 15.5px;
    }

    #loader-cover {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: lightgrey;
      transform-origin: right center;
      animation: loader_animation 2000ms linear infinite;
    }
  }
}

@keyframes loader_animation {
  0% {
    transform: scaleX(100%);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes hide_loader_animation {
  to {
    visibility: visible;
  }
}
