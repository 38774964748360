@import './colors.scss';

body.no-select * {
  user-select: none;
}

body.no-select {
  cursor: col-resize;
}

.AccountString {
  font-family: 'Courier New';
}

.navbar-expand .navbar-nav .dropdown-menu[aria-labelledby='user-action'] {
  transform: translateX(calc(-100% + 39px));
  top: 33px;
}

#message-action ~ .dropdown-menu {
  width: 400px;
  transform: translateX(calc(-100% + 39px));
  max-height: 80vh;
  overflow: auto;
  .dropdown-header {
    white-space: break-spaces;
  }
}

.navbar-light .navbar-nav .nav-link.disabled {
  path {
    stroke: #b7b7b7;
  }
}

.navbar {
  .navbar-nav .nav-link {
    // padding: 8px 16px;
  }

  .dropdown-toggle.nav-link:after {
    border: 0 !important;
  }
}

.modal {
  color: var(--text-color);
  .modal-dialog {
    display: flex;
    flex-direction: column;
    flex-flow: column;
    max-width: none !important;

    .modal-content {
      width: auto !important;
      max-width: 100vw;
      min-width: 40%;
      background-color: var(--table-body);
    }

    .modal-body {
      overflow: auto;
      background-color: var(--modal-background);
      color: var(--modal-body-text-color);
    }
  }
}

.menu-btn {
  position: absolute;
  right: 5%;
}

.menu-back-btn {
  width: 40px;
  height: 35px;
  padding: 6px 2px;
  margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid #{nth($teal, 3)};
  background-color: #{nth($teal, 3)};
  color: #{nth($grey, 7)};
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  &:hover,
  &:focus {
    color: #{nth($grey, 7)};
    background-color: #{nth($light-teal, 2)};
    border-color: #{nth($light-teal, 2)};
    border-radius: 4px;
    box-shadow: none;
  }
}

.overlay-full {
  background: rgba(80, 101, 116, 0.24);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;
  //backdrop-filter: blur(24px);
  //-webkit-backdrop-filter: blur(24px);
}

.slide-out-menu {
  z-index: 101;
  position: fixed;
  width: 400px;
  left: -400px;
  height: 100vh;
  background: #2f3c46;
  transform: translate(0, 0);
  transition: 200ms linear all;
  display: flex;
  flex-direction: column;
  .menu-container {
    overflow: auto;
    flex: 1 1 100%;
  }
}

.firefox-scrollbar-width {
  scrollbar-width: none;
}

.slide-out-menu.open {
  transform: translate(400px, 0);
}

.slide-out-menu h6 {
  margin: 24px 18px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: white;
  flex: 0 0 auto;
}

.slide-out-menu h6 img {
  margin-right: 8px;
}

.slide-out-menu .menu-search {
  margin-top: 8px;
  background: #41515e;
  padding: 24px 16px;
  position: relative;
  color: white;
}

.message-details .body {
  white-space: pre-wrap;
}

.display-quicklinks-icon {
  display: block !important;
}

.slide-out-menu .menu-item {
  position: relative;
  height: 48px;
  border-top: 1px solid #41515e;
  border-bottom: 1px solid #41515e;
  line-height: 48px;
  padding: 0 32px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: white;

  &:hover {
    background: #{nth($blue-grey, 3)};
  }

  .quick-actions {
    display: none;
    float: right;

    svg {
      margin: 0 4px;
    }
  }

  .autoplay {
    display: block;
    float: right;

    svg {
      margin: 0 4px;
    }
  }

  &:hover {
    .quick-actions {
      display: block;
    }
  }
}

.slide-out-menu .input-group-append .input-group-text {
  background: #6d899d;
  border: none;
  border-radius: 0 4px 4px 0;
}

.enquiry-folder.folder {
  padding-left: 40px;
  border-bottom: 1px solid white;
  svg {
    height: 16px;
    width: 16px;
    position: absolute;
    left: 18px;
    top: 16px;
  }
}

.slide-out-menu {
  h6.quick-links-header {
    position: relative;
    margin-left: 0 !important;
    padding-left: 40px;
    font-weight: 700;

    svg {
      height: 14px;
      width: 14px;
      position: absolute;
      left: 18px;
      top: 4px;
    }
  }

  .quick-links-more-container:hover {
    background: initial !important;
  }

  .quick-links-more {
    height: 32px;
    //line-height: 32px;
    font-size: 12px;
    font-weight: 400;
    padding: 8px 16px;
  }

  .quick-links-container {
    transition: all 1s linear;
    max-height: 200px;
    overflow: hidden;
    border-bottom: 1px solid white;

    &.open {
      max-height: none;
    }

    .menu-item {
      border: 0px;
    }
  }
}

.slide-out-menu .menu-item.folder:after {
  position: absolute;
  top: 18px;
  right: 24px;
  height: 12px;
  width: 8px;
  background: url('../assets/chevron-right.svg') no-repeat;
  background-size: contain;
  content: ' ';
}

.slide-out-menu .search-output {
  position: absolute;
  min-height: 200px;
  width: 320px;
  background: #41515e;
  left: 100%;
  padding: 24px 34px;
  overflow-y: scroll;
  max-height: 85vh;
  top: 0;
}

.slide-out-menu .search-output h6 {
  color: white;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 24px 0;
}

.slide-out-menu .search-output p {
  font-size: 12px;
  padding: 8px 0;
  margin: 0;
  line-height: 16.34px;
  color: white;
  text-decoration: none;
  font-weight: 400;

  &.search-item {
    cursor: pointer;

    .quick-actions {
      display: none;
      float: right;

      svg {
        margin: 0 4px;
      }
    }

    &:hover,
    &:focus {
      .quick-actions {
        display: block;
      }
    }
  }
}

.floating-toolbar {
  border-radius: 4px 0 0 4px;
  position: fixed;
  width: 72px;
  right: 0;
  height: calc(100vh - 153px);
  bottom: 52px;
  background: var(--floating-toolbar);
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 0 12px rgba(80, 101, 116, 0.32);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
}

.floating-toolbar .toolbar-item:first-child {
  padding-top: 18px;
  height: 51px;
}

.floating-toolbar .toolbar-item:last-child {
  padding-bottom: 18px;
  height: 51px;
}

.floating-toolbar .toolbar-item {
  height: 42px;
  padding: 9px 24px;
  cursor: pointer;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

[aria-disabled='true'] {
  cursor: not-allowed !important;
  path {
    stroke: #b7b7b7 !important;
  }
}

#change-enquiry-template {
  position: relative;
  overflow: visible;
  &:hover > ul {
    display: block;
  }
  ul {
    display: none;
    position: fixed;
    background: #{nth($blue-grey, 2)};
    z-index: 101;

    li {
      min-width: 150px;
      .selected {
        position: absolute;
        right: 12px;
        top: 0;
        svg {
          stroke: white;
        }
      }
    }
  }
}

.company-name-dispatcher {
  &:before {
    content: '';
    position: absolute;
    left: -35px;
    top: -5px;
    width: 30px;
    height: 30px;
    background: url('../assets/iptor-swe.png');
    background-size: 100px 30px;
    background-position: left top;
    background-repeat: no-repeat;
  }
  position: relative;
  color: var(--text-color);
  margin-bottom: 0;
  font-size: 14px;
  border-left: 1px solid lightgrey;
  padding-left: 5px;
}

#company-name {
  color: var(--text-color) !important;
  border-left: 1px solid lightgrey;
  line-height: 0.7;
  &:before {
    content: '';
    position: absolute;
    left: -35px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 30px;
    height: 30px;
    background: url('../assets/iptor-swe.png');
    background-size: 100px 30px;
    background-position: left top;
    background-repeat: no-repeat;
    pointer-events: none;
  }
  &:after {
    content: '';
    position: absolute;
    right: -25px;
    top: 4px;
    width: 30px;
    height: 30px;
    background: url('../assets/chevron_down2.svg');
    background-size: 16px;
    background-position: left top;
    background-repeat: no-repeat;
    z-index: 48;
  }
  #dropdown-toggle {
    line-height: 0.5;
    color: var(--text-color) !important;
    margin-bottom: 0;
    font-size: 14px;
    padding-left: 5px !important;
    padding-right: 0 !important;
    border-left: 1px solid lightgrey;
  }
}

#company-link .dropdown-menu {
  max-height: calc(100vh - 48px);
  overflow-y: auto;
}

.custom-nav-wrapper {
  align-items: center;
  .custom-avatar {
    background-color: #d0eeee;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    line-height: 15px !important;
    text-align: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 10px;
    .nav-link {
      position: relative;
      color: #00a3a5;
      &:hover {
        color: #00a3a5;
      }
    }
    .custom-avatar-body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 9px;
    }
  }
}

.error-report-title {
  color: var(--text-color);
}

.error-report-popup {
  background: var(--modal-background) !important;
}

.error-report-input {
  background: var(--error-report-input) !important;
  color: var(--text-color) !important;
}

.error-report-buttons {
  &:hover,
  &:focus {
    background-image: none !important;
    color: #{nth($grey, 7)} !important;
    background-color: #{nth($light-teal, 2)} !important;
    border-color: #{nth($light-teal, 2)} !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
}

.company-dropdown {
  .dropdown-code {
    font-family: monospace !important;
    font-style: normal;
    font-size: 15px;
  }
  .dropdown-name {
    font-size: 14px;
  }
}
