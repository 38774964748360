@import '/src/styles/colors.scss';

.calender-events-wrapper {
  .events-list {
    padding-left: 20px;
    overflow-x: hidden;
    .list-item {
      display: flex;
      align-items: center;
      .indicator {
        background: #{nth($teal, 3)};
        height: 40px;
        width: 6px;
        border-radius: 5px;
        margin-right: 10px;
      }
      .subject-name {
        font-size: 10px;
        font-weight: 600;
        line-height: 20px;
      }
      .duration {
        font-size: 10px;
        line-height: 20px;
        color: #{nth($teal, 3)};
      }
    }
  }
  .no-data-text {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
