@import './colors.scss';

// although blink-green, -orange and -red have the same functionality, to work
// properly they need to be move to separate variables
$connection-states: 'error', 'warning', 'ok';
@each $state in $connection-states {
  @keyframes opacity-pulse-#{$state} {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
@mixin animate($state) {
  animation: opacity-pulse-#{$state} 1s linear 5;
}

.icon-primary-outline path {
  fill: none;
  stroke: #{nth($teal, 3)};
}

.icon-primary-dark-outline path {
  fill: none;
  stroke: #{nth($blue-grey, 1)};
}
.icon-primary-nav path {
  fill: none;
  stroke: var(--primary-dark-svg);
}
.icon-primary-nav-ok path {
  fill: lightgreen;
  stroke: var(--primary-dark-svg);
}
.icon-primary-nav-ok-pulse path {
  fill: lightgreen;
  stroke: var(--primary-dark-svg);
  @include animate(ok);
}
.icon-primary-nav-warning path {
  fill: orange;
  stroke: var(--primary-dark-svg);
}
.icon-primary-nav-warning-pulse path {
  fill: orange;
  stroke: var(--primary-dark-svg);
  @include animate(warning);
}
.icon-primary-nav-error path {
  fill: red;
  stroke: var(--primary-dark-svg);
}
.icon-primary-nav-error-pulse path {
  fill: red;
  stroke: var(--primary-dark-svg);
  @include animate(error);
}

.icon-rss-alert-red path {
  fill: none;
  stroke: red;
}

.icon-secondary-outline path {
  fill: none;
  stroke: #{nth($pink, 3)};
}

.icon-secondary-light path {
  fill: none;
  stroke: #{nth($light-teal, 3)};
}

.icon-grey-outline path {
  fill: none;
  stroke: #{nth($grey, 7)};
}

.icon-primary-menu-outline path {
  cursor: pointer;
  fill: none;
  stroke: #{nth($teal, 3)};
}

.icon-primary-dark-menu-outline path {
  cursor: pointer;
  fill: none;
  stroke: #{nth($blue-grey, 1)};
}
.icon-modal-header path {
  fill: none;
  stroke: var(--modal-icon-color);
}

.icon-secondary-menu-outline path {
  cursor: pointer;
  fill: none;
  stroke: #{nth($pink, 3)};
}

.icon-secondary-light-menu-outline path {
  cursor: pointer;
  fill: none;
  stroke: #{nth($light-teal, 3)};
}

.icon-grey-menu-outline path {
  cursor: pointer;
  fill: none;
  stroke: #{nth($grey, 7)};
}
.icon-tab-primary-outline path {
  fill: none;
  stroke: #{nth($teal, 3)};
}
.icon-tab-primary-dark-outline path {
  fill: none;
  stroke: #{nth($blue-grey, 5)};
}

.icon-primary-fill path {
  fill: #{nth($teal, 3)};
}

.icon-primary-dark-fill path {
  fill: #{nth($blue-grey, 1)};
}

.icon-secondary-fill path {
  fill: #{nth($pink, 3)};
}

.icon-secondary-light-fill path {
  fill: #{nth($light-teal, 3)};
}

.icon-grey-fill path {
  fill: #{nth($grey, 7)};
  stroke: #{nth($grey, 7)};
}

.icon-primary-menu-fill path {
  cursor: pointer;
  fill: #{nth($teal, 3)};
}
.icon-primary-dark-menu-fill path {
  cursor: pointer;
  fill: #{nth($blue-grey, 1)};
}

.icon-secondary-menu-fill path {
  cursor: pointer;
  fill: #{nth($pink, 3)};
}

.icon-secondary-light-menu-fill path {
  cursor: pointer;
  fill: #{nth($light-teal, 3)};
}

.icon-tab-primary-fill path {
  fill: #{nth($teal, 3)};
}

.icon-tab-primary-dark-fill path {
  fill: #{nth($blue-grey, 5)};
}

.icon-tab-secondary-fill path {
  fill: #{nth($teal, 3)};
}

.icon-tab-secondary-light-fill path {
  fill: #{nth($light-teal, 3)};
}
.icon-context-dark path {
  fill: none;
  stroke: var(--icon-outline-dark);
}
.icon-dashboard path {
  fill: none;
  stroke: var(--icon-dashboard);
}

.icon-calendar path {
  cursor: pointer;
  fill: none;
  stroke: var(--modal-close-icon);
}
.icon-client-modal-mode {
  padding: 8px;
  position: absolute;
  bottom: 2%;
  right: 59%;
  cursor: pointer;
}
