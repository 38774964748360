@import 'colors';
$bg-color: #78accd;
$white: #ffffff;
$black: #333333;
$gray: #7d8994;
$orange: #de6e46;
$purple: #ae5989;

$radius: 50%;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
//html {
//  font-size: 10px;
//  font-family: sans-serif;
//  line-height: 1.6;
//}
//
//body {
//  background: $bg-color;
//  color: $black;
//  font-size: 1.5rem;
//}

.card-calendar .dropdown-menu .dropdown-item:focus:not(.active) {
  outline: none;
  font-weight: 600;
  background-color: #d0eeee;
}

.custom-dropdown-searchable {
  background: var(--calendar-body);
  z-index: 2;
  box-shadow: 0px 0px 8px rgba(80, 101, 116, 0.32);
  ul {
    max-height: 145px;
    overflow: auto;
  }
}

ul {
  list-style: none;
}

.card-calendar {
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 14px;
  transform: translate(-50%, -50%);
  z-index: 101;
  background: var(--calendar-body);
  width: 400px;
  //height: 475px;
  //max-width: 40rem;
  //min-height: 40rem;
  //margin: 5rem auto;
  display: grid;
  // grid-template-rows: 8rem auto auto;
  box-shadow: 0 1rem 2.5rem rgb(27, 64, 94);

  .pointer {
    cursor: pointer;
  }

  .row {
    padding-top: 8px;
  }

  &__header {
    padding: 1rem 0;
    color: var(--text-color);
    font-size: 14px;
    display: block;
    background: var(--calendar-header);

    //flex-wrap: wrap;
    //justify-content: space-between;
    //align-items: center;
    h3 {
      flex: 0 0 100%;
      font-size: 16px !important;
    }
    //* {
    //  flex: 0 0 40%;
    //}
  }
  &__body {
    padding: 1rem 1.5rem;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    &--days {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 2rem;
      li {
        flex: 1;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        color: $gray;
        margin: 0 1px;
      }
      li.week {
        cursor: default;
        color: #{nth($primary, 3)};
        padding-right: 10px;
      }
    }
    &--dates {
      display: grid;
      gap: 3px;
      grid-template-columns: repeat(8, 1fr);
      justify-items: center;
      text-align: center;
      li {
        width: 2rem;
        height: 2rem;
        display: grid;
        place-items: center;
        font-weight: bold;
        position: relative;
        border-radius: $radius;
        cursor: pointer;
        color: var(--text-color);
      }
      li.prev,
      li.next {
        color: #ccc;
      }
      li.week {
        cursor: default;
        color: #{nth($primary, 3)};
        padding-right: 10px;
      }
      li:not(.week):not(.conditional-disabled):hover,
      li:not(.week):not(.conditional-disabled):focus {
        background: #{nth($primary, 3)};
        outline: none;
      }
      li.conditional-highlight {
        border: 1px solid #{nth($primary, 3)};
      }
      li.conditional-holiday {
        border: 1px solid $error;
      }
      li.conditional-disabled {
        color: var(--calendar-dates-disabled);
        cursor: default;
      }
      li.active {
        background: $orange;
        color: $white;
      }
      .circle.orange {
        border-color: $orange;
        span {
          background: $orange;
        }
      }
      .circle.purple {
        border-color: $purple;
        span {
          background: $purple;
        }
      }
    }
  }
  &__footer {
    //@extend .card__header;
    padding: 1rem;
    color: $black;
    font-size: 14px;
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-between;
    //align-items: center;
    font-weight: bold;
    //height: 50px;
    button {
      flex: 0 0 50%;
    }
    .add {
      text-decoration: none;
      color: inherit;
      border: 2px solid $orange;
      border-radius: $radius;
      width: 3.5rem;
      height: 3.5rem;
      padding-top: 0.25rem;
      display: grid;
      place-items: center;
    }
  }
}
.custom-calendar-label {
  label {
    color: var(--text-color);
  }
}

.calendar-sub-heading {
  label {
    color: var(--calender-subheading);
  }
}
