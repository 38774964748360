@import './colors.scss';

.right-overlay.modal {
  .modal-dialog {
    position: fixed;
    margin: auto;
    height: 100%;
    right: 0px;

    &.modal-lg {
      width: 40%;
    }
  }
  .modal-content {
    height: 100%;
    border-radius: 0;
    border: none;
    background: var(--modal-header-bg);
    box-shadow: -8px 0px 16px rgba(80, 101, 116, 0.32);
    .modal-header {
      border-bottom: none;
      background-color: var(--client-settings-modal-header);
    }
    .modal-body {
      padding: 0 1rem;
      overflow: auto;
      &.column-ordering {
        padding: 0 20px;
      }
      .order-icon {
        margin-right: 12px;
        cursor: pointer;
      }
      .modal-controls {
        margin-bottom: 12px;
      }
    }
    .modal-footer {
      justify-content: flex-start;
      background-color: var(--client-settings-modal-header);
      border-top: none;
    }
    .modal-title {
      font-weight: 700;
      color: var(--modal-header-color);
      .modal-content {
        height: 100%;
        border-radius: 0;
        border: none;
        background: #{nth($grey, 7)};
        box-shadow: -8px 0px 16px rgba(80, 101, 116, 0.32);

        .modal-header {
          border-bottom: none;

          .close {
            color: #ffffff;
          }
        }

        .modal-body {
          padding: 0;
          overflow: auto;
        }

        .modal-footer {
          justify-content: flex-start;
          background: #{nth($blue-grey, 1)};
        }

        .modal-title {
          font-weight: 700;
        }
      }
    }
  }
}
.right-overlay-reset.modal {
  .modal-dialog {
    position: absolute;
    margin: 5%;
    top: 40%;
    width: 30%;
    right: 0;
  }
  .modal-header {
    border-bottom: none;
    background-color: var(--client-settings-modal-header);
  }
  .modal-content {
    height: 100%;
    border-radius: 0;
    border: none;
    background: var(--modal-header-bg);
    box-shadow: -8px 0px 16px rgba(80, 101, 116, 0.32);
    .modal-title {
      font-weight: 700;
      color: var(--modal-header-color);
    }
    .modal-footer {
      border-radius: 0;
      border: none;
    }
  }
}
.modal-open .modal {
  //backdrop-filter: blur(24px);
}
.list-group-item {
  font-size: 14px;
}

.window-switcher .close {
  color: var(--tab-header);
  text-shadow: none;
  &:hover {
    opacity: var(--modal-opacity);
    color: var(--modal-close-icon);
  }
}

.close {
  color: var(--modal-close-icon);
  opacity: var(--modal-opacity);
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: var(--modal-opacity-hover);
}
