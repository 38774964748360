@import 'colors';
.area-container {
  height: inherit;
  position: relative;
  button {
    position: absolute;
    width: 40px;
    height: 40px;
    img {
      width: 16px;
      height: 16px;
    }
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
  }
}
.text-area {
  display: block;
  resize: none;
  font-family: monospace;
  padding: 12px 8px 12px 8px;
  &:focus {
    border: 2px solid #{nth($primary, 3)} !important;
    //background: #{nth($secondary-light, 3)};
  }
  &:read-only {
    color: #{nth($blue-grey, 3)};
    background-color: #{nth($grey, 7)};
    border: 1px solid #{nth($grey, 4)} !important;
  }
  &:before {
    content: 'string';
  }
}

.text-right-padding {
  padding-right: 40px;
}
textarea.form-control {
  height: inherit;
  &:focus {
    border: none;
  }
}
.text-area-style {
  background-color: #{nth($grey, 7)};
  border: 1px solid #{nth($blue-grey, 3)} !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none !important;
  color: #{nth($grey, 1)};
}

.divider {
  background-color: lightgrey;
  font-family: monospace;
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  opacity: 0.5;
  margin-left: 8.5px;
}
