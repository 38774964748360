@import '/src/styles/colors.scss';

.login_btn_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .msg {
    font-size: 14px !important;
    padding: 10px 0px;
  }
  .btn {
    padding: 8px 20px;
    border: none;
    background: #{nth($teal, 3)};
    color: #fff;
  }
}
