.forgot-password-message-text {
  left: 70.56%;
  right: 3.4%;
  top: 18.65%;
  bottom: 68.95%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #506574;
  margin-top: 48px;
  min-height: calc(100vh - 30px - 208px - 80px - 36px);
}
