body {
  margin: 0;
  background: #e5e5e5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.card-table .row {
  margin: 2px 15px;
  padding: 0 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-table .col {
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-table .card-thead {
  margin: 15px 0;
  text-transform: uppercase;
}

.card-table .card-thead .col-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-table .card-thead .col-sort {
  cursor: pointer;
}

.card-table .card-tbody .row {
  background-color: var(--white);
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: var(--border-radius);
}

.card-table .card-tbody .row .col .flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-table .card-tbody .row .col .flex-container .label {
  display: none;
}

.card-table .card-tbody .row .col a {
  text-decoration: underline;
}

.selected {
  background-color: #d0eeee !important;
}

.select-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 576px) {
  .card-table .row {
    margin: 15px;
  }
  .card-table .card-thead {
    display: none;
  }
  .card-table .card-tbody .row {
    display: block;
  }
  .card-table .card-tbody .row .col .flex-container {
    margin-bottom: 20px;
  }
  .card-table .card-tbody .row .col .flex-container .label {
    display: block;
  }
}

.footer-container {
  height: 40px;
  background-color: #2f3c46;
  padding: 0px 40px;
}
.footer-container p {
  color: #ffffff;
  line-height: 40px;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.uidfooter {
  background: #eff0f2;
  padding: 16px 10px;
  position: fixed;
  bottom: 0;
  width: 99%;
}

.uidfooter button {
  padding: 5px 20px;
  border: 1px solid;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  background: transparent;
  margin-right: 10px;
}

.header-container {
  position: relative;
  background: #2f3c46;
  height: 80px;
  border-bottom: none !important;
}

.header-sticky {
  position: sticky;
  width: 100%;
  right: 0;
  left: 0;
  top: 0;
  z-index: 101;
}
.widget-header {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.widget-header a {
  color: var(--white);
}
.widget-header .notify {
  position: absolute;
  top: 7px;
  right: 1px;
}
.search-header {
  justify-content: space-between;
  margin: 0 45px !important;
  height: 80px;
}
.search-area {
  min-width: 50%;
}

.notify {
  position: absolute;
  top: -4px;
  right: -10px;
  display: inline-block;
  padding: 0.25em 0.6em;
  font-size: calc(var(--base-font-size) - 2px);
  line-height: 1;
  text-align: center;
  border-radius: 3rem;
  color: var(--yellow);
}

.brand-wrap .logo {
  margin-right: 7px;
  max-height: 42px;
  display: inline-block;
}

.search-bar {
  height: 48px !important;
}
.hamburger-menu {
  cursor: pointer;
  display: none;
  margin: 24px 26px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.logo {
  padding: 10px;
}
.header-title {
  font-size: 32px;
  color: #ffffff;
  line-height: 80px;
  width: calc(100vw - 400px);
  text-align: center;
  margin-left: 200px;
  margin-right: 200px;
}
.padding-10 {
  padding: 10px;
}
.table-title {
  padding: 0px;
}
.custom-table {
  margin: 16px;
}
.user-profile {
  margin-top: -57px;
  margin-right: 16px;
}
.user-profile label {
  color: #ffffff;
  margin-left: 10px;
  margin-top: 2px;
  display: block;
  float: right;
  font-size: 18px;
}

.loader {
  display: flex;
  min-height: 125px;
  align-items: center;
  justify-content: center;
}
.selector-btn {
  padding: 5px 20px;
  background: #00a3a5;
  color: #fff;
  border: none;
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
}
.col-selector {
  border: 1px solid #00a3a5;
  background: #fff;
  padding: 0px;
  position: absolute;
  z-index: 1;
  height: 250px;
  overflow: auto;
  margin-top: 2px;
  border-radius: 5px;
  top: 28px;
}
.selector-list {
  background: #d0eeee;
  padding: 10px 60px 10px 10px;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.list-box:after {
  content: ' ';
  background-color: #00a3a5;
  display: inline-block;
  visibility: visible;
}

.list-box:checked:after {
  content: '\2714';
  border-radius: 3px;
  height: 14px;
  display: block;
  width: 13px;
  text-align: center;
  font-size: 9px;
  color: white;
}
