@import './colors.scss';

#title[aria-label='breadcrumb'] {
  position: sticky;
  top: 0;
}

.custom-breadcrumb,
.breadcrumb {
  background: var(--background-color);
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
  padding: 8px 16px;
  a {
    color: var(--text-color);
  }
  :hover {
    text-decoration: none;
    cursor: default;
  }
}
