@import './colors.scss';

html[data-theme='dark'] {
  --background-color: #{nth($dark-mode, 11)};
  --desktop-color: #{nth($dark-mode, 1)};
  --table-header: #{nth($dark-mode, 10)};
  --table-body: #{nth($dark-mode, 9)};
  --table-text-color: #{nth($dark-mode, 6)};
  --table-row-selected-text-color: #{nth($blue-grey, 1)};
  --table-header-text-color: #{nth($grey, 7)};
  --table-resizer-color: #{nth($grey, 7)};
  --table-row-selected-background: #{nth($light-teal, 3)};
  --table-row-not-selected-background: #{nth($blue-grey, 5)};
  --table-row-not-selected-text-color: #{nth($grey, 1)};
  --input-group-icon: var(--input-form-control);
  --input-form-control: #{nth($dark-mode, 7)};
  --input-form-control-border: #{nth($dark-mode, 8)};
  --checkbox-background-color: #{nth($dark-mode, 7)};
  --checkbox-background-checked: #{nth($dark-mode, 7)};
  --checkbox-checkmark: url('../assets/checkmark-dark-mode.svg');
  --checkbox-border: #{nth($grey, 1)};
  --radio-button-dot: #{nth($teal, 3)};
  --radio-button-background-color: #{nth($dark-mode, 7)};
  --radio-button-background-checked: #{nth($dark-mode, 7)};
  --primary-dark-svg: #{nth($teal, 3)};
  --btn-outline-dark-bg: #ccc;
  --btn-outline-dark-color: #ccc;
  --modal-icon-color: #{nth($grey, 7)};
  --modal-border-bottom: #{nth($blue-grey, 5)};
  --modal-body-text-color: #{nth($dark-mode, 6)};
  --icon-outline-dark: #{nth($grey, 7)};
  --tab-button: #{nth($blue-grey, 1)};
  --icon-dashboard: #{nth($blue-grey, 5)};
  --error-messages-subheading: #{nth($grey, 7)};
  --error-messages-secondary-text: #{nth($dark-mode, 6)};
  --tab-header: #{nth($blue-grey, 1)};
  --tab-header-text-color: #e1e1e1;
  --modal-header-color: #{nth($grey, 7)};
  --text-color: #{nth($grey, 7)};
  --modal-background: #{nth($blue-grey, 1)};
  --tab-border-bottom: #{nth($teal, 3)};
  --tab-dropdown-item-color: #{nth($blue-grey, 5)};
  --dropdown-background: #3a4650;
  --scrollbar-color: #{nth($dark-mode, 6)};
  --edit-input-field: #{nth($light-teal, 3)};
  --calendar-body: #{nth($dark-mode, 9)};
  --calendar-dates-disabled: #{nth($blue-grey, 5)};
  --background-header: #{nth($dark-mode, 4)};
  --background-footer: #{nth($blue-grey, 1)};
  --context-menu-text: #{nth($grey, 6)};
  --context-menu-hover: #{nth($light-teal, 4)};
  --context-menu-hover-text: #{nth($blue-grey, 1)};
  --dark-button-hover: #{nth($teal, 1)};
  --dark-button-hover-bg: #2d404b;
  --dark-button-hover-text: #{nth($teal, 1)};
  --disabled-input-background: #313c45;
  --disabled-input-text: #ccc;
  --disabled-input-border: #55595b;
  --modal-opacity: 1;
  --modal-opacity-hover: 1;
  --modal-close-icon: #ffffff;
  --modal-header-bg: #{nth($blue-grey, 1)};
  --client-settings-modal-header: #{nth($dark-mode, 4)};
  --calendar-header: #{nth($dark-mode, 4)};
  --calendar-body: #3d4550;
  --calender-subheading: #{nth($grey, 7)};
  --text-input-hover: #{nth($teal, 3)};
  --floating-toolbar: #3a4650;
  --desktop-modal-f4: #3d4550;
  --unselected-text: #9ea5ad;
  --table-row-text: #fff;
  --custom-breadcrumb-modal: #2c3740;
  --disabled-checkbox-bg: #313c45;
  --global-loader-text: #{nth($grey, 7)};
  --search-fields-text: #ccc;
  --search-fields-bg: #{nth($dark-mode, 11)};
  --tabs-top-color: #{nth($blue-grey, 6)};
  --dashboard-cards-bg: #{nth($dark-mode, 10)};
  --dashboard-cards-text: #{nth($grey, 7)};
  --table-active-tabs: #{nth($teal, 3)};
  --table-dropdown-button: url('../assets/kebab_vertical-dark.svg');
  --table-dropdown-button-bg: #{nth($dark-mode, 11)};
  --timepicker-background: #{nth($dark-mode, 1)};
  --timepicker-label-text: #{nth($grey, 7)};
  --error-report-input: #{nth($dark-mode, 1)};
  --link-color: #8ab4f8;
}
