@import 'colors';

.react-contextmenu-wrapper {
  height: calc(100% - 25px);

  &.no-table-menu {
    height: 100%;
  }
}

.import-modal {
  display: grid !important;
  place-content: center;
}

.import-modal .import-modal-footer {
  flex-wrap: nowrap !important;
  align-items: flex-end;
}

.import-modal .modal-content {
  min-width: 350px !important;
}

.combo-icon-image-import {
  width: 12px;
  float: right;
  transform: rotate(0deg);
}

.show.dropdown .combo-icon-image-import {
  transform: rotate(180deg);
}

.import-modal .dropdown-menu .dropdown-item:focus {
  outline: none;
  font-weight: 600;
  background-color: #d0eeee;
}

.import-modal {
  .source-formatting {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 16px;

    .col {
      // flex-basis: fit-content;
      .dropdown {
        min-width: 115px;
      }
    }

    .col-label {
      word-break: keep-all;
      white-space: no-wrap;
    }
  }
  .dropdown-list {
    inset: 5px auto auto 0px !important;
  }
  .combo-icon-image {
    width: 12px;
    float: right;
    transform: rotate(0deg) !important;
  }
  .show.dropdown .combo-icon-image {
    transform: rotate(180deg) !important;
  }
}

.import-modal-title {
  margin-bottom: 0;
}

.text-right[role='gridcell'] .table-cell:not(:has(.table-prompt[hidden])) {
  padding-right: 15px;
}

.context-menu-wrapper {
  position: absolute;
  top: 25px;
  left: 0;
  box-shadow: 0px 0px 8px rgba(80, 101, 116, 0.32);
  border-radius: 4px;
  z-index: 99;
  background-color: transparent;
  max-height: 100vh;

  &.row-menu {
    overflow-y: auto;
  }

  & > .dropdown-menu {
    display: block;
    position: relative;
  }

  ul > li > ul {
    max-height: 100vh;
    overflow-y: auto;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: outside none none;
    border-radius: 4px;
    top: 0;

    li {
      cursor: pointer;
      position: relative;
      display: flex;
      padding-right: 16px;

      &:hover {
        background: var(--context-menu-hover);
      }

      &.separator {
        background: #{nth($grey, 4)};
        height: 1px;
        margin: 2px 0;

        &:last-of-type {
          height: 0;
        }
      }
    }

    .icon-container {
      height: 16px;
      width: 16px;
      //margin-right: 8px;
      img {
        height: 100%;
        width: 100%;
      }

      input[type='checkbox'] {
        display: none;
      }

      input[type='checkbox'] + label {
        display: inline-block;
        padding: 0;
        cursor: pointer;
        height: 16px;
        width: 16px;
      }

      input[type='checkbox']:checked + label {
        background-image: url('../assets/check.svg');
        background-size: 16px;
        height: 16px;
        width: 16px;
        display: inline-block;
        padding: 0;
        background-repeat: no-repeat;
      }
    }

    a {
      padding: 4px;
      font-size: 12px;
      line-height: 16px;
      color: var(--context-menu-text);
      text-decoration: none;
      width: 100%;
      font-weight: 400;
      display: flex;
      flex: 1;

      .nav-label {
        flex: 1;
        display: flex;
        margin-bottom: 0;
        cursor: pointer;
        color: var(--context-menu-text);
      }

      .nav-label:hover {
        color: var(--context-menu-hover-text);
      }

      &a:hover {
        color: var(--context-menu-hover-text);
      }
    }
  }

  .dropdown-submenu {
    position: relative;

    &:hover {
      .dropdown-menu {
        display: block;
        left: 100%;
        top: 0;
      }
    }
  }
}

.data-table {
  --gridlines-background-size: 1.2002em; // default value from Courier New (will be overwritten at runtime from code)

  margin-top: 25px;
  background-color: transparent !important;
  will-change: transform;
  //background: linear-gradient(
  //                var(--table-header) 0,
  //                var(--table-header) 34px,
  //                var(--border-color) 35px,
  //                transparent 35px,
  //                transparent 100%
  //);
  &:not(.fill-grid) {
    height: 100% !important;
  }

  .row-condition-text {
    width: var(--row-width);
    padding-left: 8px;
    background: var(--table-body);
    color: var(--unselected-text);
  }

  &.filter-container {
    //background: linear-gradient(
    //                var(--table-header) 0,
    //                var(--table-header) 69px,
    //                var(--border-color) 70px,
    //                transparent 70px,
    //                transparent 100%
    //);
  }

  //background: transparent;
  border-top: none;
  border-bottom: none;
  border-left: none;

  .rdg-header-row {
    background: var(--table-header);
    color: #fff;
    min-width: 100%;
  }

  .row-condition-text {
    cursor: not-allowed;
    white-space: pre;
    line-height: 35px;
  }

  .table-editor {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0px 6px 0 6px;
    border: 2px solid #ccc;
    vertical-align: top;
    color: var(--color);
    background-color: var(--background-color);
    font-family: inherit;
    font-size: var(--font-size);
    &:focus {
      border-color: var(--selection-color);
      outline: none;
    }
    &::placeholder {
      color: #999;
      opacity: 1;
    }
  }

  .input-group {
    height: 100%;
    .text-font {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      height: 100%;
    }
  }

  .custom-checkbox {
    padding-left: 0;
    width: 20px;
    margin: 0 auto;
    input {
      z-index: 100;
    }
    label {
      position: absolute;
      left: 0;
      &:before,
      &:after {
        left: 0;
      }
    }
  }

  .table-prompt {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
    width: 15px;
    content: ' ';

    &:after {
      content: ' ';
      position: absolute;
      height: 15px;
      width: 15px;
      right: 0px;
      top: 10px;
      z-index: 101;
    }

    &.text:after {
      background: url('../assets/editprompt_2.svg') no-repeat;
      background-size: contain;
    }

    &.date:after {
      background: url('../assets/calendar.svg') no-repeat;
      background-size: contain;
    }

    &.time:after {
      background: url('../assets/clock.svg') no-repeat;
      background-size: contain;
    }
  }

  .table-cell {
    height: calc(100% - 4px);
    width: calc(100% + 12px);
    margin: 2px -6px;
    padding: 0 6px;
    color: var(--table-row-text);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.gridlines {
    .table-cell {
      background-image: linear-gradient(90deg, transparent 50%, rgba(128, 128, 128, 0.05) 50%) !important;
      background-size: var(--gridlines-background-size) !important;
      background-position: 6px !important;
      // background-image: linear-gradient(90deg, transparent 95%, rgba(0,0,0,.1) 5%)!important;
      // background-size: 0.6001em!important;
      // background-position: 6px;
    }

    .editable-cell {
      background-image: linear-gradient(90deg, transparent 50%, rgba(128, 128, 128, 0.05) 50%) !important;
      background-size: var(--gridlines-background-size) !important;
      background-position: 6px !important;
    }
  }

  &.BufferString {
    .editable-cell {
      padding-left: 6px;
    }
  }
  .rdg-cell {
    background-color: transparent;
  }
  .rdg-row {
    background: var(--table-body);
    color: var(--unselected-text);
    &:hover {
      background-color: var(--row-hover-background-color);
      color: var(--table-row-not-selected-text-color);
    }

    .rdg-cell {
      border-right: none;
      border-top: 1px solid var(--border-color);
      background-color: transparent;
    }

    .rdg-cell[aria-selected='true'] {
      box-shadow: inset 0 0 0 2px var(--table-active-tabs);
    }
  }

  .rdg-row[aria-selected='true'] [aria-readonly='true'] {
    color: var(--text-color);
  }

  .rdg-row[aria-selected='true'] {
    background: var(--table-row-not-selected-background);
    color: var(--table-row-not-selected-text-color);
    &:hover {
      background-color: var(--table-row-selected-background);
      [aria-readonly='true'] {
        color: #373a3c;
      }
    }
  }
}

.no-table-menu .data-table {
  margin-top: 0px;
}

.rdg-focus-sink:focus ~ .row-wrapper .rdg-row[aria-selected='true'] [aria-readonly='true'] {
  background: var(--table-row-selected-background);
  color: var(--table-row-selected-text-color);
}

.rdg-focus-sink:focus ~ .row-wrapper .rdg-row[aria-selected='true'] {
  background: var(--table-row-selected-background);
  color: var(--table-row-selected-text-color);
}

.table-menu-bar {
  position: absolute;
  top: 0;
  left: 0;

  .table-menu {
    border-right: 1px solid white;
    margin-right: 1px;
  }
}

.filter-container .filter-cell {
  line-height: 35px;
  padding: 0;
  white-space: pre-wrap;

  > div {
    padding: 0 8px;

    &:first-child {
      border-bottom: 1px solid var(--border-color);
    }
  }
}

.data-table .filter {
  width: 100%;
  padding: 4px;
  margin-bottom: 10px;
  font-size: 14px;
}

.table-menu {
  //position: absolute;
  //top: 0;
  //left: 0;
  .dropdown-menu {
    max-height: 60vh;

    .dropdown-item {
      padding: 2px 20px;
      font-size: 14px;
    }
  }

  > button,
  > button:active,
  > button:focus {
    //border-color: #{nth($grey, 3)} !important;
    //border-radius: 4px 4px 0 0;
    //border-width: 0 1px 0 0;
    padding: 4px 8px;
    background-color: var(--table-active-tabs);
    color: var(--table-header-text-color);
    font-weight: 600;

    &:hover {
      //border-radius: 4px 4px 0 0;
      //border-width: 0 1px 0 0;
      padding: 4px 8px;
      //border-color: #{nth($grey, 3)} !important;
      opacity: 0.8;
      //background-color: transparent !important;
      //color: #{nth($grey, 3)} !important;
    }

    &:hover::after,
    &:active::after,
    &:focus::after {
      opacity: 0.5;
    }

    //&[id^=table-menu]:after {
    //  content: '';
    //  display: block;
    //  background: var(--table-dropdown-button) no-repeat;
    //  background-size: 16px 16px;
    //  height: 16px;
    //  width: 16px;
    //  border: 0 !important;
    //}
  }
}

.react-contextmenu {
  background-color: var(--dropdown-background);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: #373a3c;
  font-size: 12px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 0 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 102;
}

.react-contextmenu.react-contextmenu--visible:not(:has(.react-contextmenu-submenu)) {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
  overflow-y: auto;
}

.react-contextmenu:has(.react-contextmenu-submenu) {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
  overflow-y: visible; // Remark: needed to get submenu visible (eg w/w reports, upper table)
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: var(--context-menu-text);
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 4px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: var(--context-menu-hover-text);
  background-color: var(--context-menu-hover);
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item::after {
  content: url('../assets/chevron-right.png');
  display: inline-block;
  position: absolute;
  right: 7px;
}

.react-contextmenu-item.react-contextmenu-submenu {
  & > .react-contextmenu {
    display: none;
  }

  & > .react-contextmenu-item--selected ~ .react-contextmenu {
    display: block;
  }
}

.empty-table-text {
  color: var(--text-color);
}

.table-checkbox-icon {
  align-self: center;
}
