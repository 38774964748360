@import './colors.scss';

@mixin size {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: center;
  border-radius: 4px;
  padding: 8px 2px;
  box-sizing: border-box;
}

.button-bar .button-container {
  padding: 0 3px;
}

.button-container {
  height: 100%;
  width: 100%;
}
.btn.image-btn {
  min-height: 100%;
  width: 100%;
  padding: 2px 0;
}

.btn-outline-primary {
  @include size;
  border: 1px solid #{nth($teal, 3)};
  color: #{nth($teal, 3)};
  &:hover,
  &:focus {
    color: #{nth($grey, 7)};
    background-color: #{nth($teal, 3)};
    border-color: #{nth($teal, 3)};
    border-radius: 4px;
    box-shadow: none;
  }
}

.btn-outline-secondary {
  @include size;
  border: 1px solid #{nth($pink, 3)};
  color: #{nth($pink, 3)};
  &:hover,
  &:focus {
    color: #{nth($grey, 7)};
    background-color: #{nth($pink, 3)};
    border-color: #{nth($pink, 3)};
    border-radius: 4px;
    box-shadow: none;
  }
}

.btn-outline-dark {
  @include size;
  border: 1px solid var(--btn-outline-dark-bg);
  color: var(--btn-outline-dark-color);
  &:hover {
    color: var(--dark-button-hover-text);
    background-color: var(--dark-button-hover-bg);
    border-color: var(--dark-button-hover);
    border-radius: 4px;
    box-shadow: none;
  }
  &:focus {
    color: #{nth($primary, 3)};
    background-color: transparent;
    border-color: #{nth($primary, 3)};
    border-radius: 4px;
    box-shadow: none;
  }
}

.btn-primary {
  @include size;
  border: 1px solid #{nth($teal, 3)};
  background-color: #{nth($teal, 3)};
  color: #{nth($grey, 7)};
  &:focus {
    color: #{nth($grey, 7)};
    background-color: #{nth($teal, 3)};
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #{nth($teal, 3)};
    &:not(:disabled) {
      outline: 2px solid #{nth($teal, 3)};
      outline-offset: 1px;
    }
  }
  &:hover {
    &:not(:focus) {
      color: #{nth($primary, 3)};
      background-color: transparent;
      border-color: #{nth($primary, 3)};
    }
    border: 1px solid #{nth($teal, 3)};
    border-radius: 4px;
    box-shadow: none;
  }
}

.btn-secondary {
  @include size;
  border: 1px solid #{nth($pink, 3)};
  background-color: #{nth($pink, 3)};
  color: #{nth($grey, 7)};
  &:hover,
  &:focus {
    color: #{nth($grey, 7)};
    background-color: #{nth($pink, 4)};
    border-color: #{nth($pink, 4)};
    border-radius: 4px;
    box-shadow: none;
  }
}

.btn-dark {
  @include size;
  border: 1px solid #{nth($blue-grey, 1)};
  color: #{nth($grey, 7)};
  &:hover,
  &:focus {
    color: #{nth($grey, 7)};
    background-color: #{nth($blue-grey, 4)};
    border-color: #{nth($blue-grey, 4)};
    border-radius: 4px;
    box-shadow: none;
  }
}

.btn {
  @include size;
  &:disabled {
    color: var(--disabled-input-text) !important;
    background-color: var(--disabled-input-background) !important;
    border-color: var(--disabled-input-border) !important;
    border-radius: 4px !important;
    cursor: not-allowed;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #{nth($grey, 7)};
  background-color: #{nth($teal, 3)};
  border-color: #{nth($teal, 3)};
  box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #{nth($grey, 7)};
  background-color: #{nth($pink, 3)};
  border-color: #{nth($pink, 3)};
  box-shadow: none;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: var(--dark-button-hover-text);
  background-color: var(--dark-button-hover-bg);
  border-color: var(--dark-button-hover);
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #{nth($grey, 7)};
  background-color: #{nth($light-teal, 2)};
  border-color: #{nth($light-teal, 2)};
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #{nth($grey, 7)};
  background-color: #{nth($pink, 4)};
  border-color: #{nth($pink, 4)};
  box-shadow: none;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #{nth($grey, 7)};
  background-color: #{nth($blue-grey, 4)};
  border-color: #{nth($blue-grey, 4)};
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-padded-modal {
  padding-left: 10px;
  padding-right: 10px;
}
